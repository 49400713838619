import { ElNotification } from 'element-plus'
import ME from '@/graphql/user/query/me.gql'

export default defineNuxtRouteMiddleware(async (to, from) => {
  const hasToken = $cookies.get('apollo-token')
  let user = getAuthUser()
  if (hasToken && user && (!user.value || (user.value && !user.value.uuid))) {
    let response = await mutation(ME, {}, (normalizeRoute(from.fullPath) === '/login' || from.fullPath.includes('/validate') || normalizeRoute(from.fullPath) === '/auth/register') && to.fullPath === '/' ? true : false)
    if (response?.error) {
      if (response.error?.length === 0) {
        redirectToLogin(notAuthorizedMessage())
      } else if ([
        'The user is not verified',
      ].some(i => response.error.message.includes(i))) {
        redirectToLogin(notAuthorizedMessage())
      } else if (['Unauthenticated',
        'The user is not authenticated.',
        'Su usuario no está autenticado',
        'El usuario no está autenticado',
        'Your user is not logged in',
        'Error en la petición, su usuario está inactivo'].some(i => response.error.message.includes(i))) {
        redirectToLogin(response.error.message)
      }
    }
    if (response?.result) {
      if (response?.result?.data?.me) {
        user.value = {
          uuid: response?.result?.data?.me.uuid,
          first_name: response?.result?.data?.me.first_name,
          last_name: response?.result?.data?.me.last_name,
          email: response?.result?.data?.me.email,
          language: response?.result?.data?.me.language,
          password_changed_at: response?.result?.data?.me.password_changed_at ?? null,
          is_expired_verification_code: response?.result?.data?.me.is_expired_verification_code,
          avatar: response?.result?.data?.me.avatar ?? null,
          currency: response?.result?.data?.me.currency,
          customer: response?.result?.data?.me.customer ?? null,
          passenger: response?.result?.data?.me.passenger ?? null,
          current_membership: response?.result?.data?.me.current_membership ?? null,
          payment_provider: response?.result?.data?.me.payment_provider,
          measurement_system: response?.result?.data?.me.measurement_system ?? null,
          temperature_system: response?.result?.data?.me.temperature_system ?? null,
          phone: response?.result?.data?.me.phone ?? null,
          phone_country: response?.result?.data?.me.phone_country ?? null,
          donatella_web_provider: response?.result?.data?.me.donatella_web_provider ?? null,
          payments_web_provider: response?.result?.data?.me.payments_web_provider ?? null,
          created_at: response?.result?.data?.me.created_at ?? null
        }
        $cookies.set('apollo-token', $cookies.get('apollo-token'), `${365 * 20}d`)
        $cookies.set('apollo-token-refresh', $cookies.get('apollo-token-refresh'), `${365 * 20}d`)
      }
    }
  }
})

function redirectToLogin(message: any) {
  logout()
  $cookies.remove('apollo-token')
  $cookies.remove('apollo-token-refresh')
  if (message) {
    ElNotification({
      showClose: true,
      message,
      type: 'error',
      customClass: 'error-notification',
      position: 'bottom-right',
      zIndex: 3000
    })
  }
}

function notAuthorizedMessage() {
  switch (navigator.language) {
    case 'es-ES':
      return 'No estás autorizado a entrar'
    case 'en-EN':
      return 'You are not authorized to enter'
    case 'fr-FR':
      return 'Vous n\'estez pas autorisé d\'entrer'
    case 'de-DE':
      return 'Du bist nicht berechtigt, aufzurufen'
    case 'sv-SE':
      return 'Du är inte godkänt att in'
    case 'ar-AE':
      return 'ليس لديك الصلاحية للدخول'
    default:
      return 'You are not authorized to enter'
  }
}

function normalizeRoute(route: any) {
  const lastChar = route.substr(route.length - 1)
  if (lastChar === '/') {
    route = route.slice(0, -1)
  }
  return route
}