<template>
  <div class="avatar-container">
    <img
      v-if="
        getAuthUser()?.value?.avatar?.url && isImage(getAuthUser().value.avatar)
      "
      :src="getAuthUser().value.avatar.url"
      alt="avatar"
      class="avatar"
    />
    <img
      v-else-if="
        getAuthUser()?.value?.current_membership?.membership?.image?.url &&
        isImage(getAuthUser().value.current_membership.membership.image)
      "
      :src="getAuthUser().value.current_membership.membership.image.url"
      alt="avatar"
      class="avatar"
    />
    <img v-else-if="customUser" :src="customUser" alt="avatar" class="avatar" />
    <Icons v-else :name="dark ? '24px/user_white' : '24px/user'" />
  </div>
</template>

<script>
export default defineComponent({
  props: {
    dark: {
      type: Boolean,
      default: false
    },
    customUser: {
      type: String,
      default: ''
    }
  },
  methods: {
    isImage(file) {
      return file?.file_mime_type?.includes('image/')
    },
    isVideo(file) {
      return file?.file_mime_type?.includes('video/')
    }
  }
})
</script>

<style scoped lang="scss">
.avatar-container {
  width: 40px !important;
  height: 40px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  .avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
}
</style>