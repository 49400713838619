import { useI18n } from 'vue-i18n'
import { languages } from '@/models/languages'

export function getLanguagesLanding() {
  const { t } = useI18n()

  return languages.map((language) => {
    return {
      label: `${language.iso_code.toUpperCase()} - ${t(`languages.${language.name}`)}`,
      iso_code: language.iso_code.toUpperCase(),
      name: language.name,
      flag: language.flag
    }
  })
}