<template>
  <div
    class="logo-container"
    :class="`${isDark ? 'dark' : ''} ${isScrolled ? 'scrolled' : ''} logged ${
      darkBackground ? 'dark-background' : ''
    } ${lightBackground ? 'light-background' : ''} ${
      showResults && width > 1100 ? 'results' : ''
    }`"
  >
    <img
      v-if="!route?.name?.includes('register-coupon')"
      class="small-logo"
      :src="
        !isDark && !isScrolled
          ? '/logos/PALC_Marque_Black.svg'
          : '/logos/PALC_Marque_White.svg'
      "
      @click="router.push('/')"
    />
    <UtilsSearch
      v-if="!route?.name?.includes('register-coupon')"
      :light-background="lightBackground"
      @showResults="resultsEvent($event)"
    />
    <div
      class="donatella-actions"
      v-if="isUserLogged && !route?.name?.includes('register-coupon')"
    >
      <ElButton
        type="icon"
        :class="lightBackground ? 'light' : ''"
        @click="goToDonatella()"
      >
        <Icons name="24px/ai" />
      </ElButton>
    </div>
    <div class="user-actions" v-if="!route?.name?.includes('register-coupon')">
      <!-- drawer de usuario no logueado para escritorio -->
      <el-dropdown
        ref="userNotLoggedDropdown"
        trigger="click"
        :hide-on-click="false"
        popper-class="user-not-logged-dropdown"
        :teleported="false"
      >
        <ElButton
          type="icon"
          :class="lightBackground ? 'light' : ''"
          v-if="width > 1100 && !isUserLogged"
        >
          <Icons name="24px/user" />
        </ElButton>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item
              ><Icons
                name="24px/cross"
                class="icon-close"
                @click="$refs.userNotLoggedDropdown.handleClose()"
            /></el-dropdown-item>
            <el-dropdown-item>
              <ElButton
                type="primary"
                class="dark"
                @click="
                  router.push('/auth/register'),
                    $refs.userNotLoggedDropdown.handleClose()
                "
              >
                {{ $t('register') }}
              </ElButton>
            </el-dropdown-item>
            <el-dropdown-item>
              <ElButton
                type="secondary"
                class="dark"
                @click="
                  router.push('/login'),
                    $refs.userNotLoggedDropdown.handleClose()
                "
              >
                {{ $t('sign_in') }}
              </ElButton>
            </el-dropdown-item>
            <el-dropdown-item class="languages" v-if="myLanguage"
              ><el-collapse accordion v-model="languageAccordion">
                <el-collapse-item name="1">
                  <template #title>
                    <img :src="myLanguage.flag" alt="flag" />
                    <span class="body2">
                      {{ $t(`languages.${myLanguage.name}`) }}
                    </span>
                    <Icons
                      name="16px/chevron_up"
                      class="filter-icon"
                      v-if="languageAccordion === '1'"
                    />
                    <Icons
                      name="16px/chevron_down"
                      class="filter-icon"
                      v-else
                    />
                  </template>
                  <div
                    class="item"
                    :class="
                      languageSelected.toLowerCase() ===
                      language.iso_code.toLowerCase()
                        ? 'selected'
                        : ''
                    "
                    v-for="language in languages"
                    :key="language.iso_code"
                    @click="
                      $emit('setLocale', language.iso_code.toLowerCase()),
                        (languageAccordion = ''),
                        (languageSelected = language.iso_code.toLowerCase()),
                        $refs.userNotLoggedDropdown.handleClose()
                    "
                  >
                    <img :src="language.flag" alt="flag" />
                    <div class="language-name">
                      {{ $t(`languages.${language.name}`) }}
                    </div>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <!-- drawer de usuario no logueado para móvil -->
      <ElButton
        type="icon"
        :class="lightBackground ? 'light' : ''"
        v-if="width <= 1100 && !isUserLogged"
        @click="showUserNotLoggedDrawer = true"
      >
        <Icons name="24px/user" />
      </ElButton>
      <!-- drawer de usuario logueado para escritorio -->
      <el-dropdown
        :key="languageKey"
        ref="userLoggedDropdown"
        trigger="click"
        :hide-on-click="false"
        popper-class="user-dropdown"
        :teleported="false"
      >
        <ElButton
          type="icon"
          :class="lightBackground ? 'light' : ''"
          v-if="width > 1100 && isUserLogged"
        >
          <UtilsAvatar />
        </ElButton>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item
              ><Icons
                name="24px/cross"
                class="icon-close"
                @click="$refs.userLoggedDropdown.handleClose()"
            /></el-dropdown-item>
            <el-dropdown-item
              @click="
                router.push('/account'), $refs.userLoggedDropdown.handleClose()
              "
            >
              <div class="user-image">
                <UtilsAvatar :dark="true" />
              </div>
              <div class="user-name">
                {{ getAuthUser()?.value?.first_name }}
              </div>
            </el-dropdown-item>
            <el-dropdown-item
              @click="
                router.push('/nft-wallet'),
                  $refs.userLoggedDropdown.handleClose()
              "
              ><span>{{ $t('nft_wallet') }}</span>
            </el-dropdown-item>
            <el-dropdown-item
              @click="
                router.push('/my-membership'),
                  $refs.userLoggedDropdown.handleClose()
              "
              ><span>{{ $t('membership') }}</span>
            </el-dropdown-item>
            <el-dropdown-item
              @click="
                router.push('/account'), $refs.userLoggedDropdown.handleClose()
              "
              ><span>{{ $t('account') }}</span>
            </el-dropdown-item>
            <el-dropdown-item class="languages" v-if="myLanguage"
              ><el-collapse accordion v-model="languageAccordion">
                <el-collapse-item name="1">
                  <template #title>
                    <img :src="myLanguage.flag" alt="flag" />
                    <span class="body2">
                      {{ $t(`languages.${myLanguage.name}`) }}
                    </span>
                    <Icons
                      name="16px/chevron_up"
                      class="filter-icon"
                      v-if="languageAccordion === '1'"
                    />
                    <Icons
                      name="16px/chevron_down"
                      class="filter-icon"
                      v-else
                    />
                  </template>
                  <div
                    class="item"
                    :class="
                      languageSelected.toLowerCase() ===
                      language.iso_code.toLowerCase()
                        ? 'selected'
                        : ''
                    "
                    v-for="language in languages"
                    :key="language.iso_code"
                    @click="
                      $emit('setLocale', language.iso_code.toLowerCase()),
                        (languageAccordion = ''),
                        (languageSelected = language.iso_code.toLowerCase()),
                        $refs.userLoggedDropdown.handleClose()
                    "
                  >
                    <img :src="language.flag" alt="flag" />
                    <div class="language-name">
                      {{ $t(`languages.${language.name}`) }}
                    </div>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </el-dropdown-item>
            <el-dropdown-item
              ><ElButton type="link dark" @click="onLogout()">
                {{ $t('sign_out') }}
              </ElButton>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <!-- drawer de usuario logueado para móvil -->
      <ElButton
        type="icon"
        :class="lightBackground ? 'light' : ''"
        v-if="width <= 1100 && isUserLogged"
        @click="showUserLoggedDrawer = true"
      >
        <UtilsAvatar />
      </ElButton>
    </div>
    <div
      class="burguer-actions"
      v-if="!route?.name?.includes('register-coupon')"
    >
      <!-- drawer de burguer no logueado para escritorio -->
      <el-dropdown
        ref="notSigninDropdown"
        trigger="click"
        :hide-on-click="false"
        popper-class="signin-dropdown"
        :teleported="false"
      >
        <ElButton
          type="icon"
          :class="lightBackground ? 'light' : ''"
          v-if="width > 1100 && !isUserLogged"
        >
          <Icons name="24px/menu" />
        </ElButton>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item
              ><Icons
                name="24px/cross"
                class="icon-close"
                @click="$refs.notSigninDropdown.handleClose()"
            /></el-dropdown-item>
            <el-dropdown-item
              ><el-collapse accordion v-model="exploreAccordion">
                <el-collapse-item name="1">
                  <template #title>
                    <span class="body1-strong">
                      {{ $t('explore') }}
                    </span>
                    <Icons
                      name="16px/chevron_up"
                      class="filter-icon"
                      v-if="exploreAccordion === '1'"
                    />
                    <Icons
                      name="16px/chevron_down"
                      class="filter-icon"
                      v-else
                    />
                  </template>
                  <div
                    v-for="item in exploreItems"
                    :key="item"
                    class="item"
                    @click="
                      router.push(item.route),
                        $refs.notSigninDropdown.handleClose()
                    "
                  >
                    {{ $t(item.label) }}
                  </div>
                </el-collapse-item>
              </el-collapse>
            </el-dropdown-item>
            <el-dropdown-item
              @click="goToDonatella(), $refs.notSigninDropdown.handleClose()"
              ><span>{{ $t('donatella') }}</span>
            </el-dropdown-item>
            <el-dropdown-item
              @click="
                router.push('/contact-us'),
                  $refs.notSigninDropdown.handleClose()
              "
              ><span>{{ $t('contact') }}</span>
            </el-dropdown-item>
            <el-dropdown-item
              @click="
                router.push('/faqs'), $refs.notSigninDropdown.handleClose()
              "
              ><span>{{ $t('faqs') }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <!-- drawer de burguer no logueado para móvil -->
      <ElButton
        type="icon"
        :class="lightBackground ? 'light' : ''"
        v-if="width <= 1100 && !isUserLogged"
        @click="showMenuNotLoggedDrawer = true"
      >
        <Icons name="24px/menu" />
      </ElButton>
      <!-- drawer de burguer logueado para escritorio -->
      <el-dropdown
        ref="signinDropdown"
        trigger="click"
        :hide-on-click="false"
        popper-class="signin-dropdown last-no-space"
        :teleported="false"
      >
        <ElButton
          type="icon"
          :class="lightBackground ? 'light' : ''"
          v-if="width > 1100 && isUserLogged"
        >
          <Icons name="24px/menu" />
        </ElButton>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item
              ><Icons
                name="24px/cross"
                class="icon-close"
                @click="$refs.signinDropdown.handleClose()"
            /></el-dropdown-item>
            <el-dropdown-item
              ><el-collapse accordion v-model="exploreAccordion">
                <el-collapse-item name="1">
                  <template #title>
                    <span class="body1-strong">
                      {{ $t('explore') }}
                    </span>
                    <Icons
                      name="16px/chevron_up"
                      class="filter-icon"
                      v-if="exploreAccordion === '1'"
                    />
                    <Icons
                      name="16px/chevron_down"
                      class="filter-icon"
                      v-else
                    />
                  </template>
                  <div
                    v-for="item in exploreItems"
                    :key="item"
                    class="item"
                    @click="
                      router.push(item.route),
                        $refs.signinDropdown.handleClose()
                    "
                  >
                    {{ $t(item.label) }}
                  </div>
                </el-collapse-item>
              </el-collapse>
            </el-dropdown-item>
            <el-dropdown-item
              @click="
                router.push('/your-itineraries'),
                  $refs.signinDropdown.handleClose()
              "
              ><span>{{ $t('itineraries') }}</span>
            </el-dropdown-item>
            <el-dropdown-item
              @click="
                router.push('/reservations'), $refs.signinDropdown.handleClose()
              "
              ><span>{{ $t('reservations') }}</span>
            </el-dropdown-item>
            <el-dropdown-item
              @click="goToDonatella(), $refs.signinDropdown.handleClose()"
              ><span>{{ $t('donatella') }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <!-- drawer de burguer logueado para móvil	 -->
      <ElButton
        type="icon"
        :class="lightBackground ? 'light' : ''"
        v-if="width <= 1100 && isUserLogged"
        @click="showMenuLoggedDrawer = true"
      >
        <Icons name="24px/menu" />
      </ElButton>
    </div>
    <div
      class="search-actions"
      :class="isUserLogged ? 'logged' : 'not-logged'"
      v-if="showResults && width > 1100"
    >
      <ElButton type="primary" @click="$eventBus.$emit('closeSearch')">
        {{ $t('close') }}
      </ElButton>
    </div>
    <!-- drawer de menu no logueado móvil -->
    <el-drawer
      v-model="showMenuNotLoggedDrawer"
      direction="rtl"
      class="left-drawer"
      :append-to-body="true"
      modal-class="left-drawer-modal"
    >
      <template #header>
        <img src="/logos/PALC_Marque_Black.svg" alt="logo" class="logo-img" />
      </template>
      <div class="nav-section">
        <div class="nav-item accordion">
          <el-collapse accordion v-model="exploreAccordion">
            <el-collapse-item name="1">
              <template #title>
                <span class="body1-strong">
                  {{ $t('explore') }}
                </span>
                <Icons
                  name="16px/chevron_up"
                  class="filter-icon"
                  v-if="exploreAccordion === '1'"
                />
                <Icons name="16px/chevron_down" class="filter-icon" v-else />
              </template>
              <div
                v-for="item in exploreItems"
                :key="item"
                class="item"
                @click="
                  router.push(item.route), (showMenuNotLoggedDrawer = false)
                "
              >
                {{ $t(item.label) }}
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div
          class="nav-item"
          @click="goToDonatella(), (showMenuNotLoggedDrawer = false)"
        >
          {{ $t('donatella') }}
        </div>
        <div
          class="nav-item"
          @click="router.push('/contact-us'), (showMenuNotLoggedDrawer = false)"
        >
          {{ $t('contact_us') }}
        </div>
        <div
          class="nav-item"
          @click="router.push('/faqs'), (showMenuNotLoggedDrawer = false)"
        >
          {{ $t('faqs') }}
        </div>
      </div>
    </el-drawer>
    <!-- drawer de menu logueado móvil -->
    <el-drawer
      v-model="showMenuLoggedDrawer"
      direction="rtl"
      class="left-drawer"
      :append-to-body="true"
      modal-class="left-drawer-modal"
    >
      <template #header>
        <img src="/logos/PALC_Marque_Black.svg" alt="logo" class="logo-img" />
      </template>
      <div class="nav-section">
        <div class="nav-item accordion">
          <el-collapse accordion v-model="exploreAccordion">
            <el-collapse-item name="1">
              <template #title>
                <span class="body1-strong">
                  {{ $t('explore') }}
                </span>
                <Icons
                  name="16px/chevron_up"
                  class="filter-icon"
                  v-if="exploreAccordion === '1'"
                />
                <Icons name="16px/chevron_down" class="filter-icon" v-else />
              </template>
              <div
                v-for="item in exploreItems"
                :key="item"
                class="item"
                @click="router.push(item.route), (showMenuLoggedDrawer = false)"
              >
                {{ $t(item.label) }}
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div
          class="nav-item"
          @click="
            router.push('/your-itineraries'), (showMenuLoggedDrawer = false)
          "
        >
          {{ $t('itineraries') }}
        </div>
        <div
          class="nav-item"
          @click="router.push('/reservations'), (showMenuLoggedDrawer = false)"
        >
          {{ $t('reservations') }}
        </div>
        <div
          class="nav-item"
          @click="goToDonatella(), (showMenuLoggedDrawer = false)"
        >
          {{ $t('donatella') }}
        </div>
      </div>
    </el-drawer>
    <!-- drawer de usuario no logueado móvil -->
    <el-drawer
      v-model="showUserNotLoggedDrawer"
      direction="rtl"
      class="left-drawer"
      :append-to-body="true"
      modal-class="left-drawer-modal user-not-logged"
    >
      <template #header>
        <img src="/logos/PALC_Marque_Black.svg" alt="logo" class="logo-img" />
      </template>
      <div class="nav-section">
        <div class="nav-item">
          <ElButton
            type="primary"
            class="dark"
            @click="
              router.push('/auth/register'), (showUserNotLoggedDrawer = false)
            "
          >
            {{ $t('register') }}
          </ElButton>
        </div>
        <div class="nav-item">
          <ElButton
            type="secondary"
            class="dark"
            @click="router.push('/login'), (showUserNotLoggedDrawer = false)"
          >
            {{ $t('sign_in') }}
          </ElButton>
        </div>
        <div class="nav-item accordion" v-if="myLanguage">
          <el-collapse accordion v-model="languageAccordion">
            <el-collapse-item name="1">
              <template #title>
                <img :src="myLanguage.flag" alt="flag" />
                <span class="body1">
                  {{ $t(`languages.${myLanguage.name}`) }}
                </span>
                <Icons
                  name="16px/chevron_up"
                  class="filter-icon"
                  v-if="languageAccordion === '1'"
                />
                <Icons name="16px/chevron_down" class="filter-icon" v-else />
              </template>
              <div
                class="item"
                :class="
                  languageSelected.toLowerCase() ===
                  language.iso_code.toLowerCase()
                    ? 'selected'
                    : ''
                "
                v-for="language in languages"
                :key="language.iso_code"
                @click="
                  $emit('setLocale', language.iso_code.toLowerCase()),
                    (languageAccordion = ''),
                    (languageSelected = language.iso_code.toLowerCase()),
                    (showUserLoggedDrawer = false)
                "
              >
                <img :src="language.flag" alt="flag" />
                <div class="language-name">
                  {{ $t(`languages.${language.name}`) }}
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
    </el-drawer>
    <!-- drawer de usuario logueado móvil -->
    <el-drawer
      :key="languageKey"
      v-model="showUserLoggedDrawer"
      direction="rtl"
      class="left-drawer"
      :append-to-body="true"
      modal-class="left-drawer-modal user-logged"
    >
      <template #header>
        <img src="/logos/PALC_Marque_Black.svg" alt="logo" class="logo-img" />
      </template>
      <div class="nav-section">
        <div
          class="nav-item"
          @click="router.push('/account'), (showUserLoggedDrawer = false)"
        >
          <div class="user-image">
            <UtilsAvatar :dark="true" />
          </div>
          <div class="user-name">
            {{ getAuthUser()?.value?.first_name }}
          </div>
        </div>
        <div
          class="nav-item"
          @click="router.push('/nft-wallet'), (showUserLoggedDrawer = false)"
        >
          {{ $t('nft_wallet') }}
        </div>
        <div
          class="nav-item"
          @click="router.push('/my-membership'), (showUserLoggedDrawer = false)"
        >
          {{ $t('membership') }}
        </div>
        <div
          class="nav-item"
          @click="router.push('/account'), (showUserLoggedDrawer = false)"
        >
          {{ $t('account') }}
        </div>
        <div class="nav-item accordion">
          <el-collapse accordion v-model="languageAccordion" v-if="myLanguage">
            <el-collapse-item name="1">
              <template #title>
                <img :src="myLanguage.flag" alt="flag" />
                <span class="body1">
                  {{ $t(`languages.${myLanguage.name}`) }}
                </span>
                <Icons
                  name="16px/chevron_up"
                  class="filter-icon"
                  v-if="languageAccordion === '1'"
                />
                <Icons name="16px/chevron_down" class="filter-icon" v-else />
              </template>
              <div
                class="item"
                :class="
                  languageSelected.toLowerCase() ===
                  language.iso_code.toLowerCase()
                    ? 'selected'
                    : ''
                "
                v-for="language in languages"
                :key="language.iso_code"
                @click="
                  $emit('setLocale', language.iso_code.toLowerCase()),
                    (languageAccordion = ''),
                    (languageSelected = language.iso_code.toLowerCase()),
                    (showUserLoggedDrawer = false)
                "
              >
                <img :src="language.flag" alt="flag" />
                <div class="language-name">
                  {{ $t(`languages.${language.name}`) }}
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div class="nav-item">
          <ElButton
            type="link dark"
            @click="onLogout(), (showUserLoggedDrawer = false)"
          >
            {{ $t('sign_out') }}
          </ElButton>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import LOGOUT from '~/graphql/auth/mutation/logout.gql'

export default defineComponent({
  name: 'Header',
  props: {
    isDark: {
      type: Boolean,
      default: false
    },
    darkBackground: {
      type: Boolean,
      default: false
    },
    lightBackground: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const { $eventBus } = useNuxtApp()
    const router = useRouter()
    const route = useRoute()
    const { width } = useBreakpoints()
    return {
      router,
      route,
      width,
      $eventBus
    }
  },
  data() {
    return {
      window,
      myLanguage: getLanguagesLanding()[0],
      languageSelected: 'en',
      languageKey: 0,
      isScrolled: false,
      showMenuNotLoggedDrawer: false,
      showMenuLoggedDrawer: false,
      showUserLoggedDrawer: false,
      showUserNotLoggedDrawer: false,
      exploreAccordion: '',
      myParadiseAccordion: '',
      languageAccordion: '',
      showResults: false,
      languages: getLanguagesLanding(),
      exploreItems: getExploreItems()
    }
  },
  computed: {
    isUserLogged() {
      return getAuthUser()?.value?.uuid ? true : false
    }
  },
  watch: {
    '$i18n.locale': function (val) {
      this.languageSelected = val
      this.languageKey = setCustomKeyVariable()
      this.setMyLanguage()
    }
  },
  mounted() {
    if (this.isUserLogged) {
      this.languageSelected = getAuthUser()?.value?.language.toLowerCase()
    } else {
      this.languageSelected = this.$i18n.locale
    }
    this.setMyLanguage()
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      if (window.scrollY > 0) {
        this.isScrolled = true
      } else {
        this.isScrolled = false
      }
    },
    async onLogout() {
      try {
        await mutation(LOGOUT)
        logout()
        window.location.href = '/'
      } catch (e) {
        this.$showError(e)
        console.error(e)
      }
    },
    resultsEvent(event) {
      this.showResults = event
      this.$emit('showResults', event)
    },
    setMyLanguage() {
      this.myLanguage = this.languages.find(
        (language) =>
          language.iso_code.toLowerCase() ===
          this.languageSelected.toLowerCase()
      )
    }
  }
})
</script>

<style scoped lang="scss">
@import '@/assets/scss/variables/typography.scss';
.logo-container {
  width: 100%;
  z-index: 20;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 139px;
  background-color: transparent !important;
  &.results {
    z-index: 10000;
  }
  .small-logo {
    position: absolute;
    cursor: pointer;
    @media (min-width: 1100px) {
      left: 96px;
      height: 36px;
    }
    @media (max-width: 1100px) {
      height: 36px;
      left: 24px;
    }
  }
  .full-logo {
    width: 150px;
    height: 75px;
    cursor: pointer;
  }
  @media (min-width: 1100px) {
    justify-content: center;
    padding: 32px 96px;
  }
  @media (max-width: 1100px) {
    justify-content: center;
    padding: 32px 24px;
  }
  .language-selector {
    display: flex;
    position: absolute;
    cursor: pointer;
    @media (min-width: 1100px) {
      right: 330px;
      top: 77px;
      height: 24px;

      &.scrolled {
        top: 24px !important;
      }

      &.logged {
        top: 24px !important;
        right: 210px !important;
      }
    }
    @media (max-width: 1100px) {
      //
    }
    .selector {
      .body2 {
        color: var(--brand-off-black);
      }
      .body2-underline {
        color: var(--brand-off-black);
      }
    }
  }
  .login-actions {
    position: absolute;
    @media (min-width: 1100px) {
      right: 96px;
      height: 75px;
    }
    @media (max-width: 1100px) {
      height: 75px;
      right: 24px;
    }
    .actions {
      position: relative;
      top: 36px;
      span {
        cursor: pointer;
      }
      .el-button {
        margin-left: 24px !important;
      }
    }
  }
  .donatella-actions {
    position: absolute;
    @media (min-width: 1100px) {
      right: 192px;
      height: 75px;
    }
    @media (max-width: 1100px) {
      height: 75px;
      right: 121px;
    }
    display: flex;
    align-items: center;
  }
  .user-actions {
    position: absolute;
    @media (min-width: 1100px) {
      right: 144px;
      height: 75px;
    }
    @media (max-width: 1100px) {
      height: 75px;
      right: 72px;
    }
    display: flex;
    align-items: center;
  }
  .burguer-actions {
    position: absolute;
    @media (min-width: 1100px) {
      right: 96px;
      height: 75px;
    }
    @media (max-width: 1100px) {
      height: 75px;
      right: 24px;
    }
    display: flex;
    align-items: center;
  }

  .search-actions {
    position: absolute;
    &.not-logged {
      @media (min-width: 1100px) {
        right: 64px;
        height: 75px;
      }
      @media (max-width: 1100px) {
        height: 75px;
        right: 24px;
      }
    }
    &.logged {
      @media (min-width: 1100px) {
        right: 96px;
        height: 75px;
      }
      @media (max-width: 1100px) {
        height: 75px;
        right: 24px;
      }
    }
    display: flex;
    align-items: center;
    .el-button {
      @media (min-width: 1100px) {
        width: 97px;
      }
    }
  }

  &.dark {
    .language-selector {
      .selector {
        .body2 {
          color: var(--brand-white);
        }
        .body2-underline {
          color: var(--brand-white);
        }
      }
    }
    .login-actions {
      .actions {
        span {
          color: var(--brand-white);
        }
      }
    }
  }

  &.dark-background {
    background-color: var(--brand-off-black) !important;
    backdrop-filter: blur(7.5px);
  }

  &.light-background {
    background-color: var(--brand-white) !important;
  }

  &.logged {
    height: 68px;
    @media (min-width: 1100px) {
      padding: 16px 96px;
    }
    @media (max-width: 1100px) {
      padding: 16px 24px;
    }
  }

  &.scrolled {
    height: 68px;
    @media (min-width: 1100px) {
      padding: 16px 96px;
    }
    @media (max-width: 1100px) {
      padding: 16px 24px;
    }
    background-color: var(--brand-off-black) !important;
    backdrop-filter: blur(7.5px);
    .login-actions {
      .actions {
        top: 18px !important;
        span {
          color: var(--brand-white);
        }
      }
    }
    .language-selector {
      .selector {
        .body2 {
          color: var(--brand-white);
        }
        .body2-underline {
          color: var(--brand-white);
        }
      }
    }
  }
}

.left-drawer-modal {
  .left-drawer {
    .el-drawer__header {
      img {
        position: absolute;
        top: 24px;
        left: 24px;
        height: 36px;
      }
    }
    .el-drawer__body {
      margin-top: 32px;
      .login-section {
        padding: 40px 24px 40px 24px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .el-button {
          &:nth-of-type(2) {
            margin-top: 28px;
          }
        }
      }
      .navigation-section {
        padding: 16px 24px;
        .body1-strong {
          display: block;
          padding: 12px 0;
        }
      }
      .nav-section {
        padding: 16px 0 16px 0;
        .nav-item {
          @extend .body1-strong;
          color: var(--brand-off-black);
          &:not(.accordion) {
            height: 42px;
            display: flex;
            align-items: center;
          }

          :deep(.el-collapse) {
            width: 100%;
            border: 0 !important;
            .el-collapse-item {
              border: 0 !important;
              .el-collapse-item__header {
                background-color: var(--brand-white) !important;
                border: 0 !important;
                position: relative;
                text-align: left;
                height: 48px;
                i.el-icon {
                  svg {
                    display: none;
                  }
                }
                i.filter-icon {
                  position: absolute;
                  right: 0 !important;
                }
                img {
                  width: 24px;
                  height: 24px;
                  border-radius: 50%;
                  margin-right: 8px;
                }
              }
              .el-collapse-item__wrap {
                margin: 0px 8px 8px 32px;
                border-bottom: 1px solid var(--brand-white) !important;
                .el-collapse-item__content {
                  background-color: var(--brand-white) !important;
                  padding-top: 4px;
                  padding-bottom: 0 !important;
                  .item {
                    @extend .body1;
                    &:not(:first-of-type) {
                      margin-top: 16px;
                    }

                    img {
                      width: 24px;
                      height: 24px;
                      border-radius: 50%;
                      margin-right: 8px;
                      display: inline-block;
                    }
                    .language-name {
                      display: inline-block;
                    }
                    &.selected {
                      @extend .body1-strong;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.user-logged {
    .left-drawer {
      .el-drawer__body {
        .nav-section {
          .nav-item {
            @extend .body1;
            &:nth-of-type(1) {
              @extend .body1-strong;
              height: 40px;
              margin-top: 24px;
              margin-bottom: 24px;
              display: flex;
              align-items: center;
              .user-image {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background-color: var(--brand-off-black);
                display: inline-flex;
                justify-content: center;
                align-items: center;
                i {
                  position: relative;
                  left: 1px;
                }
              }
              .user-name {
                margin-left: 8px;
                max-width: 300px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }

            &.accordion {
              padding: 24px 0 0 0 !important;
            }

            &:last-of-type {
              padding: 40px 0 0 0 !important;
            }
          }
          :deep(.el-collapse) {
            .el-collapse-item {
              .el-collapse-item__wrap {
                margin: 0 8px 8px 0;
              }
            }
          }
        }
      }
    }
  }
  &.user-not-logged {
    .left-drawer {
      .el-drawer__body {
        .nav-section {
          .nav-item {
            @extend .body1;
            &:nth-of-type(1) {
              margin-top: 24px;
              margin-bottom: 8px;
            }

            .el-button {
              width: 100%;
            }

            &:last-of-type {
              padding: 40px 0 0 0 !important;
            }
          }
          :deep(.el-collapse) {
            .el-collapse-item {
              .el-collapse-item__wrap {
                margin: 0 8px 8px 0;
              }
            }
          }
        }
      }
    }
  }
}

.el-dropdown {
  :deep(.signin-dropdown) {
    border-radius: 20px !important;
    box-shadow: 0 0 0 0 !important;
    width: 200px;
    inset: 0px auto auto -159px !important;
    .el-scrollbar {
      border-radius: 20px !important;
      box-shadow: 0 0 0 0 !important;
      .el-dropdown__list {
        .el-dropdown-menu {
          padding: 0 0 40px 0;
          .el-dropdown-menu__item {
            padding: 4px 24px;
            width: 100%;
            &:nth-of-type(1) {
              height: 32px;
              &:hover {
                background-color: var(--brand-white) !important;
              }
            }
            @extend .body1-strong;
            color: var(--brand-off-black) !important;
            .el-collapse {
              width: 160px;
              border: 0 !important;
              .el-collapse-item {
                border: 0 !important;
                .el-collapse-item__header {
                  background-color: var(--brand-white) !important;
                  border: 0 !important;
                  position: relative;
                  text-align: left;
                  height: 24px;
                  i.el-icon {
                    svg {
                      display: none;
                    }
                  }
                  i.filter-icon {
                    position: absolute;
                    right: 0 !important;
                  }
                }
                .el-collapse-item__wrap {
                  padding: 0px 0px 8px 12px;
                  border-bottom: 1px solid var(--brand-white) !important;
                  .el-collapse-item__content {
                    background-color: var(--brand-white) !important;
                    padding-top: 4px;
                    padding-bottom: 0 !important;
                    .item {
                      @extend .body2;
                      &:not(:first-of-type) {
                        margin-top: 8px;
                      }
                    }
                  }
                }
              }
            }
            .icon-close {
              position: absolute;
              right: 2px;
              top: 6px;
            }

            &:hover,
            &:focus {
              background-color: var(--brand-off-white) !important;
              color: var(--brand-off-black) !important;
              .el-collapse {
                .el-collapse-item {
                  .el-collapse-item__header {
                    background-color: var(--brand-off-white) !important;
                  }
                  .el-collapse-item__wrap {
                    background-color: var(--brand-off-white) !important;
                    .el-collapse-item__content {
                      background-color: var(--brand-off-white) !important;
                    }
                  }
                }
              }
            }
          }
          .el-dropdown-menu__item--divided {
            display: none;
          }
        }
      }
    }
    .el-popper__arrow {
      display: none;
    }

    &.last-no-space {
      .el-scrollbar {
        .el-dropdown__list {
          .el-dropdown-menu {
            .el-dropdown-menu__item {
              &:last-of-type {
                padding: 4px 24px !important;
              }
            }
          }
        }
      }
    }
  }
  :deep(.user-dropdown) {
    border-radius: 20px !important;
    box-shadow: 0 0 0 0 !important;
    width: 200px;
    inset: 0px auto auto -159px !important;
    .el-scrollbar {
      border-radius: 20px !important;
      box-shadow: 0 0 0 0 !important;
      .el-dropdown__list {
        .el-dropdown-menu {
          padding: 0 0 40px 0;
          .el-dropdown-menu__item {
            padding: 4px 24px;
            width: 100%;
            &:nth-of-type(1) {
              height: 32px;
              &:hover {
                background-color: var(--brand-white) !important;
              }
            }
            &:nth-of-type(2) {
              height: 40px;
              margin-bottom: 24px;
              @extend .body1-strong;
              .user-image {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background-color: var(--brand-off-black);
                display: inline-flex;
                justify-content: center;
                align-items: center;
                i {
                  position: relative;
                  left: 2px;
                }
              }
              .user-name {
                margin-left: 8px;
                max-width: 100px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
            @extend .body2;
            color: var(--brand-off-black) !important;
            .el-collapse {
              width: 160px;
              border: 0 !important;
              .el-collapse-item {
                border: 0 !important;
                .el-collapse-item__header {
                  background-color: var(--brand-white) !important;
                  border: 0 !important;
                  position: relative;
                  text-align: left;
                  height: 24px;
                  i.el-icon {
                    svg {
                      display: none;
                    }
                  }
                  i.filter-icon {
                    position: absolute;
                    right: 0 !important;
                  }
                  img {
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    margin-right: 8px;
                  }
                }
                .el-collapse-item__wrap {
                  padding: 12px 0px 0px 12px;
                  border-bottom: 1px solid var(--brand-white) !important;
                  .el-collapse-item__content {
                    background-color: var(--brand-white) !important;
                    padding-top: 4px;
                    padding-bottom: 0 !important;
                    .item {
                      img {
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                        margin-right: 8px;
                        display: inline-block;
                      }
                      @extend .body2;
                      &:not(:first-of-type) {
                        margin-top: 8px;
                      }
                      .language-name {
                        display: inline-block;
                      }
                      &.selected {
                        @extend .body2-strong;
                      }
                    }
                  }
                }
              }
            }
            .icon-close {
              position: absolute;
              right: 2px;
              top: 6px;
            }

            &:hover,
            &:focus {
              background-color: var(--brand-off-white) !important;
              color: var(--brand-off-black) !important;
              .el-collapse {
                .el-collapse-item {
                  .el-collapse-item__header {
                    background-color: var(--brand-off-white) !important;
                  }
                  .el-collapse-item__wrap {
                    background-color: var(--brand-off-white) !important;
                    .el-collapse-item__content {
                      background-color: var(--brand-off-white) !important;
                    }
                  }
                }
              }
            }

            &.languages {
              margin-top: 24px;
              .el-collapse {
                .el-collapse-item__wrap {
                  padding: 12px 0 0 0;
                }
              }
            }

            &:last-of-type {
              margin-top: 24px;
              &:hover {
                background-color: var(--brand-white) !important;
              }
            }
          }
          .el-dropdown-menu__item--divided {
            display: none;
          }
        }
      }
    }
    .el-popper__arrow {
      display: none;
    }
  }
  :deep(.user-not-logged-dropdown) {
    border-radius: 20px !important;
    box-shadow: 0 0 0 0 !important;
    width: 200px;
    inset: 0px auto auto -159px !important;
    .el-scrollbar {
      border-radius: 20px !important;
      box-shadow: 0 0 0 0 !important;
      .el-dropdown__list {
        .el-dropdown-menu {
          padding: 0 0 24px 0;
          .el-dropdown-menu__item {
            width: 100%;
            &:nth-of-type(1) {
              height: 32px;
              margin-bottom: 4px;
            }
            &:hover {
              background-color: var(--brand-white) !important;
            }
            &:nth-of-type(2),
            &:nth-of-type(3) {
              .el-button {
                width: 100%;
              }
            }
            @extend .body2;
            color: var(--brand-off-black) !important;

            .el-collapse {
              width: 160px;
              border: 0 !important;
              .el-collapse-item {
                border: 0 !important;
                .el-collapse-item__header {
                  background-color: var(--brand-white) !important;
                  border: 0 !important;
                  position: relative;
                  text-align: left;
                  height: 24px;
                  i.el-icon {
                    svg {
                      display: none;
                    }
                  }
                  i.filter-icon {
                    position: absolute;
                    right: 0 !important;
                  }
                  img {
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    margin-right: 8px;
                  }
                }
                .el-collapse-item__wrap {
                  padding: 12px 0px 0px 12px;
                  border-bottom: 1px solid var(--brand-white) !important;
                  .el-collapse-item__content {
                    background-color: var(--brand-white) !important;
                    padding-top: 4px;
                    padding-bottom: 0 !important;
                    .item {
                      img {
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                        margin-right: 8px;
                        display: inline-block;
                      }
                      @extend .body2;
                      &:not(:first-of-type) {
                        margin-top: 8px;
                      }
                      .language-name {
                        display: inline-block;
                      }
                      &.selected {
                        @extend .body2-strong;
                      }
                    }
                  }
                }
              }
            }
            .icon-close {
              position: absolute;
              right: 2px;
              top: 6px;
            }
            &.languages {
              margin-top: 24px;
              .el-collapse {
                .el-collapse-item__wrap {
                  padding: 12px 0 0 0;
                }
              }
            }
          }
          .el-dropdown-menu__item--divided {
            display: none;
          }
        }
      }
    }
    .el-popper__arrow {
      display: none;
    }
  }
}
</style>
